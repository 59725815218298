export default defineNuxtPlugin((nuxtApp) => {

  function disableContextMenu (event: MouseEvent) {
    event.preventDefault()
  }

  nuxtApp.vueApp.directive('disable-right-click', {
    mounted () {
      document.addEventListener('contextmenu', disableContextMenu, false)
    },
    beforeUnmount () {
      document.removeEventListener('contextmenu', disableContextMenu, false)
    }
  })

})