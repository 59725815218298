import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const { public: { sentry, baseURL } } = useRuntimeConfig()

  if (!sentry || !sentry.dsn || sentry.environment === 'development') {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', baseURL],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0
  })

})