export default defineNuxtRouteMiddleware(async (to) => {
  const user = await getCurrentUser()
  // redirect to login page if not logged in
  if (!user) {
    if (to.name !== 'start' && to.name !== 'login' && to.name !== 'signup' && to.name !== 'remember' && to.name !== 'privacy' && to.name !== 'terms' && to.name !== 'gracias') {
      return navigateTo({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  }

})
